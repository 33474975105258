<template>
   <div
   class="student_assignment"
   >
      <div
         class="student_assignment_table"
      >
      <b-row
      class="mb-2"
      >
         <b-col
         cols="4"
         >
         <b-overlay :show="loading">
            <app-dashboard-widget
              class="assing_col_1"
              text="Running Stacks"
              :value="summary.runningSlaveStacksCount"
              icon="running"
              :to="{}"
            />
          </b-overlay>
         </b-col>
         <b-col
         cols="4"
         >
         <b-overlay :show="loading">
            <app-dashboard-widget
              class="assing_col_2"
              text="Completed Stacks"
              :value="summary.completedSlaveStackCount"
              icon="stop-circle"
              :to="{}"
            />
          </b-overlay>
         </b-col>
         <b-col
         cols="4"
         >
         <b-overlay :show="loading">
            <app-dashboard-widget
              class="assing_col_3"
              text="Not Started"
              :value="summary.notStartedSlaveStacksCount"
              icon="stopwatch"
              :to="{}"
            />
          </b-overlay>
         </b-col>
      </b-row>
      <b-row class="mt-3 mb-3">
         <b-col>
            <h1
            class="assign_stack_name"
            >
           Practical {{ stacks.name }}
         </h1>
         </b-col>
         <b-col>
            <h1
            class="assign_stack_attempt"
            >
           Total Attempts {{ stacks.totalAttempts }}
         </h1>
         </b-col>
      </b-row>
         <Mastertable
         :fields="fields"
         :items="slavestacks"
         :states="states"
         @reset-clicked="resetStudentAttempts"
         :key="refreshKey"
         />
      </div>
   </div>
</template>
<script>
import { getStack } from '@/shared/api/stacks-api';
import { getAllSlaveStacks, getSlaveStackSummary, resetAttempts } from '@/shared/api/slave-stacks-api';
import { getAuthorities } from '@/shared/api/authentication-api';
import AppDashboardWidget from '@/pages/dashboard/dashboard-widget.vue';
import Mastertable from '../../shared/components/mastertable/mastertable.vue';
import {
    displayConfirmDialog,
    displayNotification,
  } from '@/shared/services/modal-service';
// import { AppProviderLogo } from '@/shared/components';

export default {
   name: 'StudentAssignment',
   components: {
      Mastertable,
      AppDashboardWidget,
   },
   
   props: {
      stackId: {
         type: String,
         required: true,
      },
   },

   data: () => ({
      refreshkey: 0,
      authorities: [],
      stacks: [],
      slavestacks: [],
      summary: {
        runningSlaveStacksCount: 0,
        completedSlaveStackCount: 0,
        notStartedSlaveStacksCount: 0
      },
      showArchived: false,
      states: {
         NEW: {
            variant: 'success',
            tooltip: 'Your stack is new and has not been started yet.',
            icon: 'star-of-life',
            text: 'New',
         },
         RUNNING: {
            variant: 'primary',
            tooltip: 'Your stack is up and running!',
            icon: ['far', 'check-square'],
            text: 'Running',
         },
         TO_UPDATE: {
            variant: 'warning',
            tooltip: 'Your stack needs an update!',
            icon: 'upload',
            text: 'To Update',
         },
         STOPPED: {
            variant: 'danger',
            tooltip: 'Your stack has been stopped.',
            icon: 'stop-circle',
            text: 'stopped',
         },
         ARCHIVED: {
            variant: 'danger',
            tooltip: 'Your stack is archived.',
            icon: 'archive',
            text: 'Archived',
         },
      },
   }),
   computed: {
      fields() {
         return [
            { key: 'assignedTo.username', label: 'Name' },
            // { key: 'description', label: 'Description' },
            { key: 'noOfAttempts', label: 'Used Attempts' },
            // { key: 'totalAttempts', label: 'Total Attempts' },
            { key: 'state', label: 'State' },
            { key: 'reset_attempt', label: 'Reset Attempt' },
            // { key: 'setting', label: 'Settings' },
            // { key: 'view', label: 'Students Stacks' },
         ];
      },
   },
   async created() {
      this.authorities = await getAuthorities();
      this.stacks = await getStack(this.stackId);
      this.slavestacks = await getAllSlaveStacks(this.stackId);
      this.summary = await getSlaveStackSummary(this.stackId);
   },

   methods: {
      async resetStudentAttempts(slavestacks) {
         const message = 'You sure you want to reset attempts for this student : ' + slavestacks.assignedTo.username;
         if (await displayConfirmDialog(this, { title: 'Run request', message })) {
            await resetAttempts(slavestacks.id)
               .catch((error) => {
                  displayNotification(this, { variant: 'danger', message: "Could not reset attemp for the student : " + slavestacks.assignedTo.username});
               });
            this.refreshData();
         }
      },
      async refreshData() {
         this.slavestacks = await getAllSlaveStacks(this.stackId);
         this.refreshKey += 1;
      },
   },
};
</script>

<style scoped>
.student_assignment_table {
   min-height: 75vh;
   padding: 15px 15px;
   background-color: white;
   width: 90%;
   margin: 30px auto;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   border: 1px solid #dadce0;
   border-radius: 20px;
   padding: 30px 20px;
}

@media only screen and (max-width: 480px) {
   .student_assignment_table {
      width: 100%;
      padding: 5px 0px;
      min-height: 50vh;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border: none;
      box-shadow: none;
   }
}

@media only screen and (max-width: 768px) {
   .student_assignment_table {
      width: 100%;
      padding: 8px 8px;
      min-height: 50vh;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border: none;
      box-shadow: none;
   }
}

.assign_stack_attempt{
   text-align: end;
}
.assing_col_1{
   background-color: lightgreen;
}
.assing_col_2{
   background-color: #dc3545;
}
.assing_col_3{
   background-color: #f99000;
}

</style>
