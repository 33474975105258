<template>
  <div class="form_wizard_main">
    <div v-if="module" class="block">
      <form-wizard title="Run a new stack" subtitle="Follow the steps to start a new stack" color="#00ab94"
        error-color="#dc3545">
        <tab-content title="Stack" :before-change="checkStackNameValidation">
          <h4>{{ module.name }}</h4>
          <p>{{ module.description }}</p>

          <hr>

          <div class="form-group">
            <b-form-group label="Name" description="The name of your stack">
              <b-input v-model="stack.name" :state="stackNameValid" autofocus />
              <b-form-invalid-feedback>This field is mandatory</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Description" description="The description of your stack">
              <b-input v-model="stack.description" />
            </b-form-group>
            <!-- <b-form-group
            label="Authorized Labs"
            description="Select labs which you want to assign this assignment/practical"
          >
            <vue-multiselect
              v-model="module.authorizedLabs"
              :multiple="true"
              label="id"
              track-by="id"
              searchable
              placeholder="Select labs"
              :options="labs"
            />
          </b-form-group> -->
            <b-row>
              <b-col>
                <b-form-group label="Authorized User-Group"
                  description="Select user-group which you want to assign this assignment/practical">
                  <vue-multiselect v-model="stack.authorizedLabs" :multiple="true" label="id" track-by="id" searchable
                    placeholder="Select labs" :options="labs" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Total Number Of Attempts" description="Total number of attempts for the lab">
                  <b-input v-model="stack.totalAttempt" :state="notEmpty(stack.totalAttempt)" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Time" description="Enter the lab duration">
                  <vue-timepicker v-model="time" placeholder="HH:MM" @change="setTime(time)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-form-group
            label="Total Number Of Attempts"
            description="Total number of attempts for the lab"
          >
            <b-input
              v-model="stack.totalAttempt"
              :state="notEmpty(stack.totalAttempt)"
            />
          </b-form-group> -->
            <!-- <b-form-group
              label="Time"
              description="Enter the lab duration"
            >
              <b-form-timepicker
                v-model="stack.labDuration"
                placeholder="HH:MM"
                label-ampm="false"
                :state="notEmpty(stack.labDuration)"
                @input="stack.labDuration = $event.target.value"
              />
              <b-form-invalid-feedback>This field is mandatory</b-form-invalid-feedback>
            </b-form-group> -->
            <!-- <b-form-group
              label="Time"
              description="Enter the lab duration"
            >
            <vue-timepicker
            v-model="stack.labDuration"
            placeholder="HH:MM"
            />
          </b-form-group> -->
          </div>
        </tab-content>
        <tab-content title="Variables" :before-change="checkStackVariablesValidation">
          <h2>Input variables</h2>
          <app-stack-variable v-for="variable in stack.variables" :key="variable.name" v-model="variable.value"
            v-bind="variable" @valid="(isValid) => variable.isValid = isValid" />
        </tab-content>
        <tab-content title="Credentials">
          <h2>Select credentials for your stack</h2>
          <b-form-group>
            <b-select v-if="credentials" v-model="stack.credentialsId" :options="credentials" />
            <p v-if="!credentials">
              No credentials found for a module with provider {{ module.mainProvider }}
            </p>
          </b-form-group>
        </tab-content>
        <tab-content title="Start">
          <h2>Run</h2>
          <p>Save your stack or run it !</p>
        </tab-content>

        <!-- customizing save button -->
        <template slot="finish" slot-scope="props">
          <wizard-button v-if="props.isLastStep" :style="props.fillButtonStyle" class="wizard-footer-right finish-button"
            @click.native="save">
            <font-awesome-icon icon="save" /> Save
          </wizard-button>
        </template>

        <!-- add run button -->
        <template slot="custom-buttons-right" slot-scope="props">
          <wizard-button v-if="props.isLastStep" :style="props.fillButtonStyle"
            class="wizard-footer-right finish-button ml-3" @click.native="saveAndRun">
            <font-awesome-icon icon="rocket" /> Save and run
          </wizard-button>
        </template>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import { getModule } from '@/shared/api/modules-api';
import { createStack, runStack } from '@/shared/api/stacks-api';
import { getCredentialsList } from '@/shared/api/credentials-api';
import { getLabs } from '@/shared/api/labs-api';
// import { BFormTimepicker } from 'bootstrap-vue';
import vueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import AppStackVariable from './stack-variable.vue';

export default {
  name: 'AppStackCreation',

  components: {
    AppStackVariable,
    vueTimepicker,
  },

  props: {
    moduleId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      module: null,
      stack: null,
      stacksVariablesValidated: false,
      credentials: null,
      labs: [],
      time: null,
    };
  },

  computed: {
    stackNameValid() {
      return typeof this.stack.name !== 'undefined' && this.stack.name !== '';
    },
  },

  async created() {
    this.stack = {
      module: {
        id: this.moduleId,
      },
    };
    this.labs = await getLabs();
    this.module = await getModule(this.moduleId);
    const credentialsList = await getCredentialsList();
    this.credentials = credentialsList
      .filter((cred) => cred.provider.includes(this.module.mainProvider))
      .map((cred) => ({ value: cred.id, text: cred.name }));

    this.stack.variableValues = {};
    this.stack.variables = this.module.variables.map((variable) => ({
      ...variable,
      value: variable.defaultValue || '',
      isValid: true,
    }));
  },

  methods: {
    setTime(timeToSet) {
      if (timeToSet.mm == undefined || (timeToSet.HH == '00' && parseInt(timeToSet.mm) < parseInt('05'))) {
        timeToSet.mm = "05";
      }
      else if (timeToSet.HH == undefined) {
        timeToSet.HH = "00";
      }  
      const formattedTime = timeToSet.HH + ':' + timeToSet.mm;
      this.stack.labDuration = formattedTime;
    },
    notEmpty(field) {
      return typeof field !== 'undefined' && field !== null && field.trim() !== '';
    },
    checkStackNameValidation() {
      return this.stackNameValid;
    },
    checkStackVariablesValidation() {
      return this.stack.variables.every((variable) => variable.isValid);
    },
    stackVariablesMgmt() {
      this.stack.variableValues = {};
      this.stack.variables.forEach((variable) => {
        this.stack.variableValues[variable.name] = variable.value;
      });
    },
    async saveStack() {
      this.stackVariablesMgmt();
      return createStack(this.stack);
    },
    async save() {
      const { id: stackId } = await this.saveStack();
      this.$router.push({ name: 'stack_edition', params: { stackId } });
    },
    async saveAndRun() {
      const { id: stackId } = await this.saveStack();
      const { jobId } = await runStack(stackId);
      this.$router.push({ name: 'job', params: { stackId, jobId } });
    },
  },
};
</script>

<style scoped>
.vue__time-picker {
  width: 100%;
}

.vue__time-picker input.display-time {
  border: 1px solid #d2d2d2;
  width: 10em;
  height: 2.2em;
  padding: 0.3em 0.5em;
  font-size: 1em;
}
</style>
