<template>
  <div class="stack_setting_main">
    <div class="setting_fields">
      <div class="setting_stack_name">
        <h1
          class="ml-4 mb-2 text-white"
        >
          Practical {{ stackSettings.name }}
        </h1>
      </div>
      <b-row
        class="mt-4"
      >
        <b-col
        cols="12"
        mt-3
        >
          <b-form-group
          label="Instructions For Students"
          description="Instructions for student to access lab"
          >
            <b-form-textarea
            id="textarea-no-resize"
            v-model="stackSettings.labInstructions"
            rows="3"
            no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-form-group
            label="Total Number Of Attempts"
            description="Total number of attempts for the lab"
          >
            <b-input
              v-model="stackSettings.totalAttempts"
              :state="notEmpty(stackSettings.totalAttempts)"
            />
          </b-form-group>
          <b-form-group label="Time" description="Enter the lab duration" class="mt-3">
              <vue-timepicker v-model="time" placeholder="HH:MM" @change="setTime(time)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col
        cols="12"
        class="mt-3"
        >
          <b-form-group
            label="Authorized User-Group"
            description="Select the user-group for which this practical should be available"
          >
            <vue-multiselect
              v-model="stackSettings.authorizedLabs"
              :multiple="true"
              label="id"
              track-by="id"
              searchable
              placeholder="Select labs"
              :options="labs"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        id="setting_save_btn"
        variant="primary"
        class="mt-5 ml-4 mb-5"
        @click="saveStack"
      >
        <font-awesome-icon icon="save" /> Save
      </b-button>
    </div>
  </div>
</template>

<script>
import { getStack, saveStack } from '@/shared/api/stacks-api';
import { getLabs } from '@/shared/api/labs-api';
import vueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

import {
    displayConfirmDialog,
    displayNotification,
  } from '@/shared/services/modal-service';

export default {
  name: 'Stacksetting',
  components: {
    vueTimepicker,
},
  props: {
    stackId: {
      type: String,
      required: true,
    },
  },

  data: function data() {
    return {
      stackSettings: null,
      labs: [],
      time: null,
    };
  },
  async created() {
    this.stackSettings = await getStack(this.stackId);
    this.labs = await getLabs();
    this.time = this.stackSettings.labDuration;
  },
  methods: {
    notEmpty(field) {
      return typeof field !== 'undefined' && field !== null;
    },
    setTime(timeToSet) {
      let formattedTime = "";
      if (typeof timeToSet == 'string') {
        console.log(timeToSet+' '+typeof timeToSet);
        var [hour,min] = timeToSet.split(":");
        formattedTime = hour + ':' + min;
      }
      else {
        if (timeToSet.mm == undefined || (timeToSet.HH == '00' && parseInt(timeToSet.mm) < parseInt('05'))) {
          timeToSet.mm = "05";
        }
        else if (timeToSet.HH == undefined) {
          timeToSet.HH = "00";
        }
        formattedTime = timeToSet.HH + ':' + timeToSet.mm;
      } 
      this.stackSettings.labDuration = formattedTime;
    },
    saveStack() {
      saveStack(this.stackSettings)
        .then(() => displayNotification(this, { variant: 'success', message: 'Stack saved.' }))
        .catch(({ message }) => {
          displayNotification(this, { variant: 'info', message: `Error saving stack: ${message}` });
        });
    },
  },
};

</script>

<style scoped>
.stack_setting_main {
  padding: 0px 0px;
}

.setting_fields {
  background-color: white;
  width: 70%;
  margin: auto;
  margin: 0px auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #dadce0;
  border-radius: 1px;
}

.form-group {
  width: 93%;
  margin: auto;
}

#textarea-no-resize,
#labduration {
  outline: none !important;
  box-shadow: none !important;
}

.setting_fields #setting_authlab {
  border-radius: 0%;
  margin-left: -5px;
  margin-right: -5px;
}

#setting_save_btn {
  background-color: #FF9900;
  padding: 8px 20px;
  border: none;
  border-radius: 20px;
  font-size: 12px;
}

.setting_stack_name {
  border-bottom: solid #f3f3f3 2px;
  background-color: #073065;
  color: white;
  padding: 20px 0px;
}

#second_row {
  padding: 0px 0px;
}

.ml-4 {
  margin-left: 22px;
}

@media only screen and (max-width: 480px) {
  .setting_fields {
    width: 100%;
    padding: 5px 0px;
  }
}

@media only screen and (max-width: 768px) {
  .setting_fields {
    width: 100%;
    padding: 8px 8px;
  }
}
</style>
