import axios from 'axios';

export const getAllSlaveStacks = async (stackId) => axios.get(`/api/slave-stacks/studentstacks/${stackId}`);

export const getStudentSlaveStacks = async () => axios.get(`/api/slave-stacks`);

export const getSlaveStack = async (slaveStackId) => axios.get(`/api/slave-stacks/${slaveStackId}`);

export const getSlaveStackSummary = async (stackId) => axios.get(`/api/slave-stacks/studentstackssummary/${stackId}`);

export const resetAttempts = async (stackId) => axios.put(`/api/slave-stacks/studentstacks/reset/${stackId}`);

// export const resetSlaveStackAttempt = async (stackId) => axios.get(`/api/slave-stacks/reset-attempt/${stackId}`);
