<!-- <template>
  <div class="stack_main" >
    <div class="page_controls">
      <b-form-checkbox
        v-if="authorities.includes('ROLE_PROFESSOR')"
        v-model="showArchived"
        switch
      >
        Show Non-archived practicals
      </b-form-checkbox>
    </div>
    <b-card-group columns>
      <b-card
        v-for="stack in visibleStacks"
        :key="stack.id"
        :sub-title="stack.description"
        :header-class="stack.module.mainProvider || 'unknown'"
        no-body
      >
        <template v-slot:header>
          <h1>{{ stack.name }}</h1>
          <app-provider-logo
            :provider="stack.module.mainProvider || 'unknown'"
            size="40px"
          />
        </template>

        <b-card-body>
          <p>{{ stack.description }}</p>
        </b-card-body>

        <b-card-footer>
          <b-badge
            :id="'badge-' + stack.id"
            pill
            :variant="states[stack.state].variant"
          >
            <font-awesome-icon :icon="states[stack.state].icon" />&nbsp;{{ states[stack.state].text }}
          </b-badge>
          <b-tooltip :target="'badge-' + stack.id">
            {{ states[stack.state].tooltip }}
          </b-tooltip>
        </b-card-footer>

        <b-card-footer>
          <b-button
            :to="{ name: 'stack_edition', params: { stackId: stack.id }}"
            title="Edit this stack"
            variant="primary"
            class="mr-1"
          >
            <font-awesome-icon icon="edit" />
          </b-button>
        </b-card-footer>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
  import { getStacks } from '@/shared/api/stacks-api';
  import { AppProviderLogo } from '@/shared/components';
  import { getAuthorities } from '@/shared/api/authentication-api';

  export default {
    name: 'AppStacks',
    components: {
      AppProviderLogo,
    },
    data: () => ({
      authorities: [],
      stacks: [],
      showArchived: false,
      states: {
        NEW: {
          variant: 'success',
          tooltip: 'Your stack is new and has not been started yet.',
          icon: 'star-of-life',
          text: 'new',
        },
        RUNNING: {
          variant: 'primary',
          tooltip: 'Your stack is up and running !',
          icon: ['far', 'check-square'],
          text: 'running',
        },
        TO_UPDATE: {
          variant: 'warning',
          tooltip: 'Your stack needs an update !',
          icon: 'upload',
          text: 'to update',
        },
        STOPPED: {
          variant: 'danger',
          tooltip: 'Your stack has been stopped.',
          icon: 'stop-circle',
          text: 'stopped',
        },
        ARCHIVED: {
          variant: 'danger',
          tooltip: 'Your stack is archived.',
          icon: 'archive',
          text: 'archived',
        },
      },
    }),
    computed: {
      visibleStacks() {
        if (this.showArchived) {
          return this.stacks;
        }
        return this.stacks.filter((stack) => stack.state !== 'ARCHIVED');
      },
    },
    async created() {
      this.stacks = await getStacks();
      this.authorities = await getAuthorities();
    },
  };
</script> -->
<!-- <template>
  <div class="stack_main">
    <div class="page_controls">
      <b-form-checkbox
        v-model="showArchived"
        switch
        size="lg"
      >
        Show Non-archived stacks
      </b-form-checkbox>
    </div> -->
    <!-- <b-table
      class="mt-5"
      :items="visibleStacks"
      :fields="tableFields"
      responsive
    > -->
      <!-- <template v-slot:cell(name)="row">
        <div class="d-flex align-items-center">
          <p class="stack_name">
            {{ row.value }}
          </p>
          <app-provider-logo
            :provider="row.item.module.mainProvider || 'unknown'"
            size="40px"
          />
        </div>
      </template> -->
      <!-- <template v-slot:cell(description)="row">
        <p class="stack_description">
          {{ row.value }}
        </p>
      </template>
      <template v-slot:cell(noOfAttempts)="row">
        <p class="stack_description">
          {{ row.value }}
        </p>
      </template> -->
      <!-- <template v-slot:cell(totalAttempts)="row">
        <p class="stack_description">
          {{ row.value }}
        </p>
      </template> -->
      <!-- <template v-slot:cell(state)="row">
        <b-badge :variant="states[row.value].variant">
          <font-awesome-icon :icon="states[row.value].icon" />
          &nbsp;{{ states[row.value].text }}
        </b-badge>
        <b-tooltip :target="'badge-' + row.item.id">
          {{ states[row.value].tooltip }}
        </b-tooltip>
      </template> -->
      <!-- <template v-slot:cell(actions)="row">
        <b-button
          :to="{ name: 'stack_edition', params: { stackId: row.item.id }}"
          title="Edit this stack"
          variant="primary"
          class="mr-1"
        >
          <font-awesome-icon icon="edit" />
        </b-button>
      </template>
      <template v-slot:cell(setting)="row">
        <b-button
          :to="{ name: 'Stacksetting', params: { stackId: row.item.id }}"
          title="Stack Setting"
          variant="primary"
          class="mr-1"
        >
        <font-awesome-icon icon="cog" />
        </b-button>
      </template>
       <template v-slot:cell(actions)="row">
        <b-button
          :to="{ name: 'stack_edition', params: { stackId: row.item.id }}"
          title="Reset attempts"
          variant="primary"
          class="mr-1"
        >
          <font-awesome-icon icon="reset" />
        </b-button>
      </template> -->
      <!-- <template v-slot:cell(view)="row">
        <b-button
          :to="{ name: 'StudentStacks', params: { stackId: row.item.id }}"
          title="Edit this stack"
          variant="primary"
          class="mr-1"
        >
          <font-awesome-icon icon="file" />
        </b-button>
      </template> -->
    <!-- </b-table>
  </div>
</template> -->
<template>
    <div class="lab_assignment_main">
      <div class="lab_assignment_table">
        <div class="page_controls">
          <b-form-checkbox
            v-if="authorities.includes('ROLE_PROFESSOR')"
            v-model="showArchived"
            switch
            size="lg"
          >
            Show Non-archived stacks
          </b-form-checkbox>
        </div>
        <Mastertable
          :fields="fields"
          :items="visibleStacks"
          :states="states"
          @edit-clicked="handleEditClicked"
          @send-clicked="sendTostudentAssignment"
          @setting-clicked="sendTostudentsetting"
        />
      </div>
    </div>
</template>

<script>
import { getStacks } from '@/shared/api/stacks-api';
import { getStudentSlaveStacks } from '@/shared/api/slave-stacks-api';
import { getAuthorities } from '@/shared/api/authentication-api';
import Mastertable from '../../shared/components/mastertable/mastertable.vue';
// import { AppProviderLogo } from '@/shared/components';

export default {
  name: 'AppStacks',
  components: {
    Mastertable,
  },
  data: () => ({
    authorities: [],
    stacks: [],
    showArchived: false,
    states: {
      NEW: {
        variant: 'success',
        tooltip: 'Your stack is new and has not been started yet.',
        icon: 'star-of-life',
        text: 'New',
      },
      RUNNING: {
        variant: 'primary',
        tooltip: 'Your stack is up and running!',
        icon: ['far', 'check-square'],
        text: 'Running',
      },
      TO_UPDATE: {
        variant: 'warning',
        tooltip: 'Your stack needs an update!',
        icon: 'upload',
        text: 'To Update',
      },
      STOPPED: {
        variant: 'danger',
        tooltip: 'Your stack has been stopped.',
        icon: 'stop-circle',
        text: 'stopped',
      },
      ARCHIVED: {
        variant: 'danger',
        tooltip: 'Your stack is archived.',
        icon: 'archive',
        text: 'Archived',
      },
    },
  }),
  computed: {
    visibleStacks() {
      if (this.showArchived) {
        return this.stacks;
      }
      return this.stacks.filter((stack) => stack.state !== 'ARCHIVED');
    },
    fields() {
      let fields = [
        { key: 'name', label: 'Name' },
        { key: 'description', label: 'Description' },
        { key: 'state', label: 'State' },
        { key: 'actions', label: 'Actions' }
      ];
      if (this.authorities.includes('ROLE_PROFESSOR')) {
        fields.splice(2, 0, { key: 'totalAttempts', label: 'Total Attempts' });
        fields.push({ key: 'setting', label: 'Settings' });
        fields.push({ key: 'view', label: 'Students Stacks' });
      }
      else {
        fields.splice(2, 0, { key: 'noOfAttempts', label: 'Attempts Made' });
        fields.splice(3, 0, { key: 'masterStack.totalAttempts', label: 'Total Attempts' });
      }
      return fields;
    }
  },
  async created() {
    this.authorities = await getAuthorities();
    if (this.authorities.includes('ROLE_STUDENT') && this.authorities.length == 1) {
      this.stacks = await getStudentSlaveStacks();
    }
    else {
      this.stacks = await getStacks();
    }
  },
  methods: {
    handleEditClicked(stack) {
      this.$router.push({ name: 'stack_edition', params: { stackId: stack.id } });
    },
    sendTostudentAssignment(stack) {
      this.$router.push({ name: 'student_assignment', params: { stackId: stack.id } });
    },
    sendTostudentsetting(stack) {
      this.$router.push({ name: 'stack_setting', params: { stackId: stack.id } });
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.card-header h1 {
  color: white;
}

.card-header.unknown {
  background-color: #FF9900;
}

.card-header.google {
  background-color: #2f6fd8;
}

.card-header.docker {
  background-color: #2496ed;
}

.card-header.aws {
  background-color: #ea8c00;
}

.card-header.azurerm {
  background-color: #007cc1;
}

.metadata {
  display: flex;
  justify-content: space-between;
}

.lab_assignment_table {
  min-height: 75vh;
  padding: 15px 15px;
  background-color: white;
  width: 90%;
  margin: 30px auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #dadce0;
  border-radius: 20px;
}

.stack_name {
  font-size: 17px;
  color: #242121;
  font-weight: 500;

}

.stack_description {
  font-size: 17px;
  color: #242121;
  font-weight: 500;
}

.mr-1 {
  background-color: #0069d9;
}

@media only screen and (max-width: 480px) {
  .lab_assignment_table {
    width: 100%;
    padding: 5px 0px;
    min-height: 50vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: none;
    box-shadow: none;
  }

}

@media only screen and (max-width: 768px) {
  .lab_assignment_table {
    width: 100%;
    padding: 8px 8px;
    min-height: 50vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: none;
    box-shadow: none;

  }

}
</style>
