<template>
  <div>
    Time till lab ends : {{ remainingTime }}
  </div>
</template>

<script>
  export default {
    props: {
      targetTime: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        remainingTime: '00:00:00',
        flag: 0,
      };
    },
    mounted() {
      this.startTimer();
    },
    watch: {
      targetTime: {
        immediate: true, // Run the watcher immediately when the component is mounted
        handler(newTargetTime) {
          this.startTimer();
        }
      }
    },
    methods: {
      startTimer() {
        const target = new Date(this.targetTime).getTime(); // Convert target time to milliseconds

        // Calculate the remaining time
        const now = new Date().getTime();

        let difference = target - now;

        // Update the remaining time every second
        const intervalId = setInterval(() => {
          const updatedNow = new Date().getTime();
          difference = target - updatedNow;
          // Check if remaining time is less than or equal to 0
          if (difference <= 0 || isNaN(difference)) {
            // Clear the interval to stop the timer
            clearInterval(intervalId);
            this.remainingTime = '00:00:00'; // Set remaining time to 00:00:00 when timer reaches 0
          } else {
            this.remainingTime = formatTime(difference);
            if (difference <= 900000 && this.flag == 0) {
              this.showNotification('15 minutes left to job');
              this.flag = 1;
          }
        }
      }, 1000);
    },
    showNotification(message) {
      if (window.Notification && Notification.permission !== 'denied') {
        new Notification('Remaining Time', { body: message });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            new Notification('Remaining Time', { body: message });
          }
        });
      }
    },
  },
};

  function formatTime(time) {
    let hours = Math.floor(time / (1000 * 60 * 60));
    let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((time % (1000 * 60)) / 1000);

    // Add leading zeros if necessary
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    seconds = seconds.toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }
</script>
