<template>
  <div class="block">
    <div class="block_head">
      <h2>Outputs</h2>
      <small>These are the outputs of your stack.</small>
    </div>
    <table
      v-if="Object.keys(outputs).length !== 0"
      class="table"
    >
      <thead class="thead-light">
        <tr>
          <th>Variable</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(output, key) in outputs"
          :key="key"
        >
          <td>
            {{ key }}
            <br>
            <small>{{ description(key) }}</small>
          </td>
          <td v-if="key.includes('pem')">
            <button
              class="pem_download_btn"
              variant="contained"
              color="primary"
              @click="onDownload(output.value)"
            >
                Download Pem File
            </button>
          </td>
          <td
          v-else
          class="output_text"
          >
          {{ output.value }}</td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="block_content"
    >
      <img
        src="@/assets/images/ghost.png"
        width="32"
      >
      No outputs found !
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppStackOutputs',
    props: {
      outputs: { type: Object, default: () => {} },
      moduleOutputs: { type: Array, default: () => [] },
    },
    methods: {
      description(outputName) {
        const output = this.moduleOutputs.find((out) => out.name === outputName);
        return output ? output.description : '';
      },
      onDownload(value) {
        var content = value;
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'key.pem';
        link.href = url;
        link.click();
        URL.revokeObjectURL(url);
      },
    },
  };
</script>

<style scoped>
small {
  color: white;
}
.output_text{
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
}
.pem_download_btn{
  background-color: #ff9900;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 20px;
}
</style>
