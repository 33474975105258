<template>
    <div>
        <b-progress :value="100" variant="primary" :animated="animate" class="mt-3"></b-progress>
    </div>
</template>
<script>
import { BProgress } from 'bootstrap-vue';
  export default {
    name: 'Loader',
    components: {
        BProgress,
    },
    data() {
      return {
        animate: true
      }
    }
}
</script>